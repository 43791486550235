import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  showEmail(data) {
    var url = ConfigAPI.baseURL + "notifications/email/" + data.id + Session.getToken();
    return instance.get(url);
  },
  saveEmail(data) {
    var params = data
    var url = ConfigAPI.baseURL + "notifications/email/" + data.id + Session.getToken();
    return instance.post(url,params);
  },
  testEmail(data) {
    var params = data
    var url = ConfigAPI.baseURL + "notifications/email/" + data.id + '/test' + Session.getToken();
    return instance.post(url,params);
  },

  showSMS(data) {
    var url = ConfigAPI.baseURL + "notifications/sms/" + data.id + Session.getToken();
    return instance.get(url);
  },
  saveSMS(data) {
    var params = data
    var url = ConfigAPI.baseURL + "notifications/sms/" + data.id + Session.getToken();
    return instance.post(url,params);
  },
  testSMS(data) {
    var params = data
    var url = ConfigAPI.baseURL + "notifications/sms/" + data.id + '/test' + Session.getToken();
    return instance.post(url,params);
  },

  obtenerWhatsapp() {
    var url = ConfigAPI.baseURL + "notifications/whatsapp" + Session.getToken();
    return instance.get(url);
  },
  mostrarWhatsapp(id) {
    var url = ConfigAPI.baseURL + "notifications/whatsapp/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarWhatsapp(data) {    
      var params = data
      var url = ConfigAPI.baseURL + "notifications/whatsapp" + Session.getToken();
      return instance.post(url,params);
  },
  editarWhatsapp(data) {    
      var params = data    
      var url = ConfigAPI.baseURL + "notifications/whatsapp/"+ data.id + Session.getToken();
      return instance.post(url,params);
  },
  eliminarWhatsapp(id) {
    var url = ConfigAPI.baseURL + "notifications/whatsapp/"+ id + Session.getToken();
    return instance.delete(url);
  },
  showWhatsapp(data) {
    var url = ConfigAPI.baseURL + "notifications/whatsapp/" + data.id + Session.getToken();
    return instance.get(url);
  },
  saveWhatsapp(data) {
    var params = data
    var url = ConfigAPI.baseURL + "notifications/whatsapp/" + data.id + Session.getToken();
    return instance.post(url,params);
  },
  testWhatsapp(data) {
    var params = data
    var url = ConfigAPI.baseURL + "notifications/whatsapp/" + data.id + '/test' + Session.getToken();
    return instance.post(url,params);
  },
  
  showPush(data) {
    var url = ConfigAPI.baseURL + "notifications/push/" + data.id + Session.getToken();
    return instance.get(url);
  },
  savePush(data) {
    var params = data
    var url = ConfigAPI.baseURL + "notifications/push/" + data.id + Session.getToken();
    return instance.post(url,params);
  },
  testPush(data) {
    var params = data
    var url = ConfigAPI.baseURL + "notifications/push/" + data.id + '/test' + Session.getToken();
    return instance.post(url,params);
  },

  manualSend(data) {
    var params = data
    var url = ConfigAPI.baseURL + "notifications/manual-send" + Session.getToken();
    return instance.post(url,params);    
  }
  
}

export default servicesAPI;
