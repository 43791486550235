<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Notificaciones - Envío Manual</h4>
            <div class="small text-muted">Envío manual de notificaciones a usuarios</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>          
          <b-col lg="6" md="6">                    
            <b-row>          
              <b-col lg="6" md="6">            
                <b-form-group label="Medio">                  
                  <b-form-select v-model="crud.form.method" :options="arr.select.methods"></b-form-select>                    
                </b-form-group>
              </b-col>
              <b-col lg="6" md="6">            
                <b-form-group label="Origen" v-if="crud.form.method=='push'">                  
                  <b-form-select v-model="crud.form.source" :options="arr.select.sources"></b-form-select>                    
                </b-form-group>
              </b-col>    
              <b-col lg="6" md="6">            
                <b-form-group label="Tipo de Usuario">                  
                  <b-form-select v-model="crud.form.type" :options="arr.select.types"></b-form-select>                    
                </b-form-group>
              </b-col>                                   
              <b-col lg="6" md="6">
                <FindObject type="staff-subscription-push" 
                            @select-object="loadStaff($event)" 
                            :valueID="crud.form.staff_id" 
                            render="search"
                            v-show="crud.form.type == 'staff'"
                            :key="'notifications_staff_'+crud.form.staff_id"/>
              
                <FindObject type="customers-subscription-push" 
                            @select-object="loadCustomers($event)" 
                            :valueID="crud.form.customer_id" 
                            render="search"
                            v-show="crud.form.type == 'customer'"
                            :key="'notifications_customer_'+crud.form.customer_id"/>

                <FindObject type="suppliers-subscription-push" 
                            @select-object="loadSuppliers($event)" 
                            :valueID="crud.form.supplier_id" 
                            render="search"
                            v-show="crud.form.type == 'supplier'"
                            :key="'notifications_supplier_'+crud.form.supplier_id"/>
              </b-col>
              <b-col lg="12">
                <b-form-group label="Título">
                  <b-form-input v-model="crud.form.title" placeholder="Título"></b-form-input>
                </b-form-group>                           
              </b-col>
              <b-col lg="12">
                <b-form-group label="Mensaje">
                  <b-textarea v-model="crud.form.message" placeholder="Mensaje" rows="3"></b-textarea>            
                </b-form-group>
              </b-col>       
              <b-col lg="12">
                <b-form-group label="URL" description="URL de redireccionamiento en el click">
                  <b-form-input v-model="crud.form.url" placeholder="URL"></b-form-input>
                </b-form-group>                           
              </b-col>                     
              <b-col lg="12" md="12">                  
                <hr>
              </b-col>
              <b-col lg="6" md="6">                  
                <b-button variant="outline-secondary" class="pull-left" @click="clean()">Limpiar</b-button>          
              </b-col>              
              <b-col lg="6" md="6">                  
                <b-button variant="dark" class="pull-right" @click="send()">Enviar</b-button>          
              </b-col>
            </b-row>               
          </b-col>
          <b-col lg="6" md="6">   
            <b-form-group label="Resultado">  
              <prism-editor v-model="crud.form.result" language="json" class="notifications-send-manual-result" :readonly="true"></prism-editor>            
            </b-form-group>          
          </b-col>          
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
        </b-row>
      </b-card>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  
  import FindObject from '@/components/inc/find/findObject'
  import PrismEditor from 'vue-prism-editor'
  import "prismjs";
  import "prismjs/themes/prism.css";
  import 'prismjs/components/prism-json';

  export default {
    components: {          
      FindObject,
      PrismEditor,
    },    
    data: () => {
      return {      
        access: {
          module_id: Modules.NOTIFICACIONES,
          profile_id: Profiles.PERSONAL,
          view_reference: 'manualSend',
          elements: {}
        }, 
        crud: {   
          form: {
            method: 'push',
            source: 'SYSTEM',
            type: 'empty',            
            title: '',
            message: '',
            url: '',   
            staff: null,
            staff_id: 0,
            customer: null,
            customer_id: 0,
            supplier: null,
            supplier_id: 0,
            result: ''
          }        
        },   
        arr: {
          select: {
            methods: [
              { value: 'email', text: 'EMAIL', disabled: true },     
              { value: 'sms', text: 'SMS', disabled: true },     
              { value: 'whatsapp', text: 'WHATSAPP', disabled: true },     
              { value: 'push', text: 'NOTIFICACIONES PUSH', disabled: false },
            ],     
            sources: [
              { value: 'SYSTEM', text: 'SISTEMA' },     
              { value: 'WEB', text: 'SITIO WEB' },     
            ],     
            types: [
              { value: 'empty', text: 'SIN USUARIO' },     
              { value: 'staff', text: 'STAFF' },     
              { value: 'customer', text: 'CLIENTES' },     
              { value: 'supplier', text: 'PROVEEDORES' },     
            ],                                
          }
        }
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },         
    methods: {
      loadStaff(object){
        if(object){
          this.crud.form.staff = object
          this.crud.form.staff_id = object.id        

          this.crud.form.customer = null
          this.crud.form.customer_id = 0
          this.crud.form.supplier = null
          this.crud.form.supplier_id = 0                              
        } else {
          this.crud.form.staff = null
          this.crud.form.staff_id = 0
        }
      },
      loadCustomers(object){
        if(object){
          this.crud.form.customer = object
          this.crud.form.customer_id = object.id        

          this.crud.form.staff = null
          this.crud.form.staff_id = 0
          this.crud.form.supplier = null
          this.crud.form.supplier_id = 0                              
        } else {
          this.crud.form.customer = null
          this.crud.form.customer_id = 0
        }        
      },
      loadSuppliers(object){
        if(object){
          this.crud.form.supplier = object
          this.crud.form.supplier_id = object.id        

          this.crud.form.staff = null
          this.crud.form.staff_id = 0
          this.crud.form.customer = null
          this.crud.form.customer_id = 0                              
        } else {
          this.crud.form.supplier = null
          this.crud.form.supplier_id = 0
        }             
      },  

      send() {
        if(this.crud.form.type=='staff' && !this.crud.form.staff_id) {
          this.$awn.alert("Debes seleccionar un STAFF");
          return false
        }
        if(this.crud.form.type=='customer' && !this.crud.form.customer_id) {
          this.$awn.alert("Debes seleccionar un CLIENTE");
          return false
        }
        if(this.crud.form.type=='supplier' && !this.crud.form.supplier_id) {
          this.$awn.alert("Debes seleccionar un PROVEEDOR");
          return false
        }                

        this.$bvModal.msgBoxConfirm('¿Confirmar el envío de la notificación?', {
          title: 'Enviar notificación',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'ENVIAR',
          cancelVariant: 'outline-dark',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'black',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();    

            var result = serviceAPI.manualSend(this.crud.form);
              
            result.then((response) => { 
              loader.hide()              

              this.crud.form.result = JSON.stringify(response.data, null, 4)
              
              this.$awn.success("Notificación Enviada");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })            
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })            
      },
      clean() {
        this.crud.form.method = 'push'
        this.crud.form.source = 'system'
        this.crud.form.type = 'staff'          
        this.crud.form.title = ''
        this.crud.form.message = ''
        this.crud.form.icon = ''
        this.crud.form.url = ''   
        this.crud.form.staff = null
        this.crud.form.staff_id = 0
        this.crud.form.customer = null
        this.crud.form.customer_id = 0
        this.crud.form.supplier = null
        this.crud.form.supplier_id = 0        
        this.crud.form.result = ''
      }
    } 
  }
</script>
<style>
  .notifications-send-manual-result {
    height: 400px;
  }
  .notifications-send-manual-result pre {
    height: 400px;
  }  
</style>